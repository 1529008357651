import { Offering } from 'types/Portfolio/StructuredOffering'
import { isPotentialOffering, LiveDataOffering, OfferingIdentifier, PotentialOffering } from 'types'
import { environment } from 'config/environment'

type Customization = 'HideCloseDate' | 'HidePortfolioValue' | 'HideFundraising' | 'HideProtocolFee'
type ValueCustomization = Partial<LiveDataOffering | PotentialOffering>

type OfferingKey = `${Offering['_type'] | PotentialOffering['_type']}-${string}`

/**
 * Production configuration
 * - Potential Offering id=21eba12d-3d3a-4a11-b793-3d278c10811a - Adapt3r
 */
const customizations: Record<OfferingKey, Customization[]> = {
  ['PotentialOffering-21eba12d-3d3a-4a11-b793-3d278c10811a']: [
    'HidePortfolioValue',
    'HideFundraising',
    'HideProtocolFee',
  ],
}

/**
 * Production configuration
 * - Asset Offering id=a3a8c090-7e5c-4a33-a044-c33029434f83 - Adapt3r
 * - Asset Offering id=2ae53f01-ded4-4e6e-9bde-9e7e07875843 - Adapt3r new
 */
const valueCustomizations: Record<OfferingKey, ValueCustomization> = {
  ['AssetOffering-a3a8c090-7e5c-4a33-a044-c33029434f83']: {
    name: 'Adapt3r Short-Term U.S. Treasury Bill Fund',
  },
  ['AssetOffering-2ae53f01-ded4-4e6e-9bde-9e7e07875843']: {
    name: 'Adapt3r Short-Term U.S. Treasury Bill Fund',
  },
}

export function hasCustomization(
  offering: LiveDataOffering | PotentialOffering | OfferingIdentifier,
  type: Customization,
) {
  if (!environment.isProduction) return false

  const key = getKey(offering)

  return key in customizations && customizations[key].includes(type)
}

export function applyValueCustomizations<T extends Offering | PotentialOffering>(offering: T): T {
  if (!environment.isProduction) return offering

  const offeringCustomizations = valueCustomizations[getKey(offering)]
  if (!offeringCustomizations) return offering

  return {
    ...offering,
    ...offeringCustomizations,
  }
}

function getKey(offering: Offering | PotentialOffering | OfferingIdentifier): OfferingKey {
  return isPotentialOffering(offering) ? `PotentialOffering-${offering.id}` : `${offering._type}-${offering.id}`
}
