import { RestrictionDefinition } from 'domain/investor/restrictions/types'

export const SUPPORTED: RestrictionDefinition[] = [
  {
    restriction: 'AllowAll',
    contract: 'carbon_allowAllLenderVerifier',
  },
  {
    restriction: 'KYC_nonUS',
    contract: 'KYC_nonUS_globalWhitelistLenderVerifier_Wrapper',
  },
  {
    restriction: 'KYC_US',
    contract: 'KYC_UsOnly_globalWhitelistLenderVerifier_Wrapper',
  },
  {
    restriction: 'KYC_anyRegion',
    contract: 'KYC_noGeoRestriction_globalWhitelistLenderVerifier_Wrapper',
  },
  {
    restriction: 'Adapt3r_KYC_nonUS',
    contract: 'Adapt3r_KYC_nonUS_globalWhitelistLenderVerifier_Wrapper',
  },
]
