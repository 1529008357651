import { isAssetOffering, Offering } from 'types/Portfolio/StructuredOffering'
import { isPotentialOffering, PotentialOffering } from 'types'
import { formatBasisPoints } from 'utils/formatting'

export function getOfferingApy(portfolio: Offering | PotentialOffering) {
  if (!isPotentialOffering(portfolio) && portfolio.apyCalculationMethod === 'manual' && !!portfolio.manualAPYOverride) {
    return portfolio.manualAPYOverride.value.toFixed(2) + '%'
  }

  if (isAssetOffering(portfolio) && portfolio.currentApyInBPS) {
    return formatBasisPoints(portfolio.currentApyInBPS)
  }

  return portfolio.targetApy ? portfolio.targetApy + '%' : 'N/A'
}
