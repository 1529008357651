import { ChainId } from '@usedapp/core'

export const ARCHBLOCK = {
  multiWithdrawalController: {
    [ChainId.Mainnet]: '0x7932231B7Cf083f1F3E3483A3b93d4D31675B5B4',
    [ChainId.Sepolia]: '0xe3A8E2ea634692D910fdBF01894587a033265d49',
  },
  minimumDepositController: {
    [ChainId.Mainnet]: '0xf9633bD42AC3e54235CA3a6cCC80957f8eefdC16',
    [ChainId.Sepolia]: '0x1b2259dC0f77369b3dbf5baD4Dbe1acb509cF3f4',
  },
  capitalCallsDepositController: {},
  structuredPortfolioCapitalCallsVault: {},
  structuredAssetVaultCapitalCallsVault: {},
}

export const ARCHBLOCK_WHITELISTS = {
  Adapt3r_KYC_nonUS_globalWhitelistLenderVerifier: {
    [ChainId.Mainnet]: '0x5d965BB9171773ff5B012aD9805175c55a9E2B09',
  },
  Adapt3r_KYC_nonUS_globalWhitelistLenderVerifier_Wrapper: {
    [ChainId.Mainnet]: '0xb85C1D478e3640246221E745cA5960D634433A02',
  },
  KYC_nonUS_globalWhitelistLenderVerifier: {
    [ChainId.Mainnet]: '0xAe48bea8F3FC1696DC8ec75183705CeE1D071B05',
    [ChainId.Sepolia]: '0xE325c97c476894B77e5A13c01d407238bEABfF91',
  },
  KYC_nonUS_globalWhitelistLenderVerifier_Wrapper: {
    [ChainId.Mainnet]: '0x0592A5aB52c9436D0fF41B8ABd78d802363614aB',
    [ChainId.Sepolia]: '0x78B2289c5E2B2C55B6F3714CD06B9F3A039f3c6A',
  },
  KYC_noGeoRestriction_globalWhitelistLenderVerifier: {},
  KYC_noGeoRestriction_globalWhitelistLenderVerifier_Wrapper: {},
  KYC_UsOnly_globalWhitelistLenderVerifier: {},
  KYC_UsOnly_globalWhitelistLenderVerifier_Wrapper: {},
}

export const BERYLLIUM = {
  beryllium_fixedInterestOnlyLoans: {
    [ChainId.Mainnet]: '0xD1b4F0b9bc1998369702f6Ae86eae4488368EbD6',
  },
}

export const CARBON = {
  carbon_protocolConfigProxy: {
    [ChainId.Mainnet]: '0x359Ca1E991DFc3BEBE7bbd2CA4CF1F7046d6dB08',
    [ChainId.Sepolia]: '0x5fDAA4Dd2f2321f5DaBB298587FD70f784ec22FF',
  },
  carbon_allowAllLenderVerifier: {
    [ChainId.Mainnet]: '0xebE580D4467454D1aB7e768aa250921dc6Ae38Da',
    [ChainId.Sepolia]: '0xAD71070fF4B3a1c149fc6d1703B6A7F0FD781925',
  },
  structuredPortfolioFactory: {
    [ChainId.Mainnet]: '0xfB95F2409819B53039EDAe19D4630f46caA6C3b6',
    [ChainId.Sepolia]: '0xbB924aed28c80abde18e9d6271584477B1cB0056',
  },
  // TODO: move to fluorine
  structuredAssetVaultFactory: {
    [ChainId.Mainnet]: '0x5Def383172C7dFB6F937e32aDf5be4D252168eDA',
    [ChainId.Sepolia]: '0x5E43437ff2f3585372f8c7bAA7dC9db0bfAf0E5D',
  },
}

export const FLUORINE = {
  fluorine_disabledTransferController: {
    [ChainId.Mainnet]: '0x477Ff9709A9C7C31837E92993972101D56b3938E',
  },
  fluorine_protocolConfigProxy: {
    [ChainId.Mainnet]: '0xb037169e8d88C00f29d1398872364C81D76aA77c',
    [ChainId.Sepolia]: '0x06d01107BE54f6A4c5DeC8Ef652D7B29ABAA00c6',
  },
}

export const ADDRESSES = {
  ...BERYLLIUM,
  ...CARBON,
  ...FLUORINE,
  ...ARCHBLOCK,
  ...ARCHBLOCK_WHITELISTS,
} as const
