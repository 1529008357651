import styled from 'styled-components'
import { useIsManagerWhitelisted } from 'hooks'
import { useEthers } from '@usedapp/core'
import { AddIcon } from 'components/atoms/Icons'
import { LinkButton } from 'components/atoms/LinkButton'

interface Props {
  text?: string
}

export const AddOfferingButton = ({ text }: Props) => {
  const { account, chainId } = useEthers()
  const { isWhitelisted } = useIsManagerWhitelisted(account, chainId)

  if (!isWhitelisted) {
    return null
  }

  return (
    <NewOfferingButton href="/offering/create" view="primary" narrow icon={<AddIcon />}>
      {text ?? 'New Offering'}
    </NewOfferingButton>
  )
}

const NewOfferingButton = styled(LinkButton)`
  padding: 8px 16px 8px 40px;
`
