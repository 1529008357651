import { format, formatDistanceToNowStrict, isBefore, secondsToMilliseconds } from 'date-fns'
import { format as formatTz } from 'date-fns-tz'
import { BigNumber } from 'ethers'

function defaultFormat(date: number | Date) {
  return format(date, 'd MMM yyyy')
}

const formatNumberDate = (dateInSeconds: number) => {
  const date = secondsToMilliseconds(dateInSeconds)
  return defaultFormat(date)
}

export function formatDate(dateInSeconds: Date): string
export function formatDate(dateInSeconds: number | BigNumber): string
export function formatDate(dateInSeconds: number | BigNumber | Date): string {
  if (dateInSeconds instanceof Date) {
    return defaultFormat(dateInSeconds)
  } else if (typeof dateInSeconds === 'number') {
    return formatNumberDate(dateInSeconds)
  } else {
    return formatNumberDate(dateInSeconds.toNumber())
  }
}

function defaultFormatDateTime(date: number | Date) {
  return formatTz(date, 'h:mm b z, MMM d, yyyy')
}

const formatNumberDateTime = (dateInSeconds: number) => {
  const date = secondsToMilliseconds(dateInSeconds)
  return defaultFormatDateTime(date)
}

export function formatDateTime(dateInSeconds: Date): string
export function formatDateTime(dateInSeconds: number | BigNumber): string

export function formatDateTime(dateInSeconds: number | BigNumber | Date): string {
  if (dateInSeconds instanceof Date) {
    return defaultFormatDateTime(dateInSeconds)
  }

  const dateAsNumber = typeof dateInSeconds === 'number' ? dateInSeconds : dateInSeconds.toNumber()
  return formatNumberDateTime(dateAsNumber)
}

export const daysFromNow = (dateInSeconds: number) => {
  const date = secondsToMilliseconds(dateInSeconds)
  let suffix = ' left'
  if (isBefore(date, new Date())) {
    suffix = ' ago'
  }
  const distanceInDays = formatDistanceToNowStrict(date, {
    unit: 'day',
    roundingMethod: 'ceil',
  })

  return distanceInDays + suffix
}
