import {
  differenceInDays,
  differenceInMonths,
  formatDistanceStrict,
  formatDuration,
  intervalToDuration,
  startOfDay,
} from 'date-fns'

export const formatPortfolioTerm = (startDate: Date, endDate: Date) => {
  const days = differenceInDays(endDate, startDate)

  if (days <= 30) {
    return formatDistanceStrict(startDate, endDate)
  }

  const format = []
  const months = differenceInMonths(endDate, startDate)

  if (months <= 1) {
    format.push('days')
  } else if (months < 12) {
    format.push('months')
    format.push('days')
  } else {
    format.push('years')
    format.push('months')
  }

  const duration = intervalToDuration({
    start: startOfDay(startDate),
    end: endDate,
  })
  return formatDuration(duration, { format })
}

export const formatPortfolioFundraisingPeriod = (startDate: Date) => {
  return formatPortfolioTerm(new Date(), startDate)
}
