import { addYears, isAfter, startOfToday } from 'date-fns'

export const CREATE_EVERGREEN_FIXED_YEARS = 100
const DISPLAY_EVERGREEN_FIXED_YEARS = 75

export function getEvergreenOfferingEndDate(startDate: Date) {
  return addYears(startDate, CREATE_EVERGREEN_FIXED_YEARS)
}

export function isEvergreenPortfolio(endDate: Date) {
  return isAfter(endDate, addYears(startOfToday(), DISPLAY_EVERGREEN_FIXED_YEARS))
}
