import { utils } from 'ethers'

export const DEPOSIT_CONTROLLER_ABI = [
  'function ceiling() view returns (uint256)',
  'function depositAllowed(uint8) view returns (bool)',
  'function depositFeeRate() view returns (uint256)',
  'function lenderVerifier() view returns (address)',
] as const

export const DEPOSIT_CONTROLLER_INTERFACE = new utils.Interface(DEPOSIT_CONTROLLER_ABI)

export const MINIMUM_DEPOSIT_CONTROLLER_ABI = [
  ...DEPOSIT_CONTROLLER_ABI,
  'function initialize(address manager, address _lenderVerifier, uint256 _depositFeeRate, uint256 _minimumDeposit, uint256 _ceiling, bool _areLiveDepositsAllowed)',
  'function configure(uint256 newCeiling, uint256 newFeeRate, uint256 newMinimumDeposit, address newLenderVerifier, tuple(uint8 status, bool value))',
] as const

export const MINIMUM_DEPOSIT_CONTROLLER_INTERFACE = new utils.Interface(MINIMUM_DEPOSIT_CONTROLLER_ABI)

export const CAPITAL_CALLS_DEPOSIT_CONTROLLER = [
  ...DEPOSIT_CONTROLLER_ABI,
  'function initialize(address manager, address _lenderVerifier, uint256 _depositFeeRate, uint256 _ceiling, address _capitalCallsVault)',
] as const

export const CAPITAL_CALLS_DEPOSIT_CONTROLLER_INTERFACE = new utils.Interface(CAPITAL_CALLS_DEPOSIT_CONTROLLER)
