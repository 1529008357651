import { ABI_VIEWS } from 'types/abi'
import { useChainCall } from './useChainCalls'
import { DEPOSIT_CONTROLLER_ABI, DEPOSIT_CONTROLLER_INTERFACE } from 'constants/abis'
import { SupportedChainId } from 'constants/chain'

export function useDepositControllerCall<T>(
  chainInfo: { chainId: SupportedChainId | undefined; address: string } | undefined | null,
  functionName: ABI_VIEWS<typeof DEPOSIT_CONTROLLER_ABI>,
  args: unknown[] = [],
  skip?: boolean,
): T | undefined {
  return useChainCall<T>(
    chainInfo?.chainId,
    chainInfo?.address,
    functionName,
    DEPOSIT_CONTROLLER_INTERFACE,
    [args],
    skip,
  ).data?.[0]
}
