import { Column } from 'components/atoms/Column'
import styled from 'styled-components'

export const DashboardContainer = styled(Column)`
  row-gap: 24px;
  margin-bottom: 32px;

  @media ${({ theme }) => theme.queries.tabletAndSmaller} {
    row-gap: 32px;
    margin-bottom: unset;
  }
`
