import { InputLabel } from 'components/atoms/Input'
import { ChangeEventHandler, ForwardedRef, forwardRef, ReactNode, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { commonInputBorderStyles, commonInputStyles, commonInputWrapperStyles } from 'components/atoms/common'
import { UseFormRegisterReturn } from 'react-hook-form'

export interface TextAreaProps {
  id: string
  label?: string
  error?: string
  disabled?: boolean
  placeholder?: string
  isRequired?: boolean
  isOptional?: boolean
  tooltip?: ReactNode
  className?: string
  autogrow?: boolean
}

type Props = TextAreaProps & UseFormRegisterReturn<string>

const TextAreaComponent = (
  {
    id,
    label,
    error,
    disabled,
    placeholder,
    isOptional,
    isRequired,
    tooltip,
    className,
    autogrow,
    ...hookFormProps
  }: Props,
  forwardedRef: ForwardedRef<HTMLTextAreaElement>,
) => {
  const props: Omit<TextAreaProps, 'id'> = {
    label,
    error,
    disabled,
    placeholder,
    isOptional,
    isRequired,
    tooltip,
    autogrow,
  }
  const [value, setValue] = useState<string>()
  const onChange: ChangeEventHandler<HTMLTextAreaElement> = async (e) => {
    await hookFormProps.onChange(e)
    setValue(e.target.value)
  }

  const localRef = useRef<HTMLTextAreaElement | null>(null)
  useAutosizeTextArea(localRef.current, !!autogrow, value)

  const handleRefs = (instance: HTMLTextAreaElement | null) => {
    localRef.current = instance

    if (typeof forwardedRef === 'function') {
      forwardedRef(instance)
    } else if (forwardedRef && typeof forwardedRef === 'object') {
      forwardedRef.current = instance
    }
  }

  return (
    <TextAreaWrapper className={className} {...props}>
      {label && (
        <TextAreaLabel htmlFor={id} {...props}>
          {label}
        </TextAreaLabel>
      )}
      <StyledTextArea
        rows={autogrow ? 1 : undefined}
        id={id}
        {...props}
        {...hookFormProps}
        ref={handleRefs}
        onChange={onChange}
      />
    </TextAreaWrapper>
  )
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(TextAreaComponent)

const TextAreaWrapper = styled.div<Omit<TextAreaProps, 'id'>>`
  position: relative;
  width: 100%;
  ${commonInputWrapperStyles}
`

const StyledTextArea = styled.textarea<TextAreaProps>`
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 132px;
  padding: 12px 16px 8px 16px;
  ${commonInputStyles}
  ${commonInputBorderStyles}
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.GrayNurse : theme.colors.White)};
  ${({ autogrow }) =>
    autogrow &&
    css`
      height: unset;
      min-height: unset;
      transition: none;
      resize: none;
      overflow: hidden;
    `}
`

const TextAreaLabel = styled(InputLabel)`
  top: 0;
  transform: translateY(-50%);
`

const useAutosizeTextArea = (textAreaRef: HTMLTextAreaElement | null, autogrow: boolean, value: string | undefined) => {
  useEffect(() => {
    if (autogrow && textAreaRef) {
      textAreaRef.style.height = '0px'
      const scrollHeight = textAreaRef.scrollHeight
      textAreaRef.style.height = scrollHeight + 'px'
    }
  }, [autogrow, textAreaRef, textAreaRef?.value, value])
}
