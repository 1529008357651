import { useEthers } from '@usedapp/core'
import { useDepositControllerCall } from 'hooks/calls'
import { getPortfolioRestriction, Restriction } from 'domain/investor/restrictions'
import { Offering } from 'types/Portfolio/StructuredOffering'

export function useGetPortfolioRestriction(portfolio: Offering): Restriction {
  const { account } = useEthers()
  const depositControllerAddress = portfolio.tranches[0].depositController
  const lenderVerifierAddress = useDepositControllerCall<string>(
    { chainId: portfolio.chainId, address: depositControllerAddress },
    'lenderVerifier',
    [],
    !depositControllerAddress,
  )
  return getPortfolioRestriction(lenderVerifierAddress, portfolio.chainId, account)
}
