import { isPotentialOffering, PotentialOffering } from 'types'
import { Offering } from 'types/Portfolio/StructuredOffering'

export function getMinimumInvestment(portfolio: Offering | PotentialOffering) {
  if (isPotentialOffering(portfolio)) {
    return portfolio.minimumInvestment
  }

  return portfolio.tranches[0]?.minimumInvestment
}
