import { getContractAddress, isAddressEqual } from 'utils'
import { Restriction, RestrictionDefinition } from 'domain/investor/restrictions/types'
import { SUPPORTED } from 'domain/investor/restrictions/definitions'
import { LENDER_VERIFIER_INTERFACE } from 'constants/abis'
import { SupportedChainId } from 'constants/chain'

export function getPortfolioRestriction(
  lenderVerifierAddress: string | undefined,
  chainId: SupportedChainId,
  account: string | undefined,
): Restriction {
  if (!lenderVerifierAddress) {
    return {
      restriction: 'Unknown',
    }
  }
  const definition = SUPPORTED.find(checkSupportedDefinition(chainId, lenderVerifierAddress))

  if (!definition || definition.restriction === 'Unknown') {
    return {
      restriction: 'Unknown',
    }
  }

  if (definition.restriction === 'AllowAll') {
    return {
      restriction: 'AllowAll',
    }
  }

  return {
    restriction: definition.restriction,
    check: {
      callData: {
        address: lenderVerifierAddress,
        functionName: 'isAllowed',
        abi: LENDER_VERIFIER_INTERFACE,
        args: [account],
      },
    },
  }
}

const checkSupportedDefinition = (chainId: SupportedChainId, lenderVerifier: string) => {
  return ({ contract }: RestrictionDefinition) => {
    const contractAddress = getContractAddress(contract, chainId)

    return isAddressEqual(contractAddress, lenderVerifier)
  }
}
