import { constants } from 'ethers'

import { ADDRESSES, Contract } from 'constants/addresses'
import { SupportedChainId } from 'constants/chain'

export function getContractAddress(contract: Contract, chainId: SupportedChainId) {
  const address = ADDRESSES[contract]

  return address?.[chainId as keyof typeof address] ?? constants.AddressZero
}
