import { TextArea, TextAreaProps } from 'components/atoms/TextArea'
import { useFormError } from 'hooks'
import { useFormContext } from 'react-hook-form'
import { InputErrorWrapper } from '../InputErrorWrapper'
import { Text } from 'components/atoms/Text'
import { ReactNode } from 'react'

export interface FormTextAreaProps extends Omit<TextAreaProps, 'value' | 'error'> {
  description?: ReactNode
}

export const FormTextArea = ({ description, ...props }: FormTextAreaProps) => {
  const { id, disabled } = props
  const {
    register,
    formState: { isSubmitting },
  } = useFormContext()

  const error = useFormError(id)

  return (
    <InputErrorWrapper error={error}>
      <TextArea {...props} error={error} disabled={disabled || isSubmitting} {...register(id)} />
      {description && (
        <Text variant="body2" color="light">
          {description}
        </Text>
      )}
    </InputErrorWrapper>
  )
}
