import { PortfolioStatus } from 'types'
import { compareAsc } from 'date-fns'

const statusOrder: Record<PortfolioStatus, number> = {
  [PortfolioStatus.Preview]: 0,
  [PortfolioStatus.Fundraising]: 1,
  [PortfolioStatus.Open]: 2,
  [PortfolioStatus.Full]: 3,
  [PortfolioStatus.Closed]: 4,
}

interface SortableByStatusAndStartDates {
  status: PortfolioStatus
  startDeadline: Date
  startDate: Date
}

export function sortByStartTime(a: SortableByStatusAndStartDates, b: SortableByStatusAndStartDates) {
  if (a.status !== b.status) {
    return statusOrder[a.status] - statusOrder[b.status]
  }

  if (a.status === PortfolioStatus.Fundraising) {
    return compareAsc(a.startDeadline, b.startDeadline)
  }

  return compareAsc(a.startDate, b.startDate)
}
